//
// page-title.scss
//

.page-title-box {
    .page-title {
        letter-spacing: 2%;
        font-family: 'Exo2-Regular', sans-serif;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #404249;
        margin-top: 10px;
    }
}

.page-breadcrumb {
    margin-bottom: 0;
    padding-bottom: 0;
}

@include media-breakpoint-down(sm) {
    .page-title-box {
        .page-title {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 70px;
        }
        .breadcrumb {
            display: none;
        }
    }
}

@media (max-width: 640px) {
    .page-title-box {
        .page-title-right {
            display: none;
        }
    }
}

@media (max-width: 419px) {
    .page-title-box .breadcrumb {
        display: none;
    }
}
