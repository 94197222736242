//
// apexcharts.scss
//
.apex-charts {
  min-height: 10px !important;
  text {
      font-family: $font-family-base !important;
      font-size: 14px !important;
  }
  .apexcharts-canvas {
      margin: 0 auto;
  }
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  font-family: $font-family-base !important;
}

.apexcharts-legend-series {
  font-weight: 600;
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: $gray-100;
}

.apexcharts-legend-text {
  color: $gray-600 !important;
  font-family: $font-family-base !important;
  font-size: 13px !important;
}

.apexcharts-pie-label {
  fill: $white !important;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
  text {
      font-family: $font-family-base !important;
      fill: $gray-500;
  }
}
