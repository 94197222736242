.filter-bar {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    color: #abccf7;
    background-color: #fff;
    border: 1px solid #abccf7;
    border-radius: 12px;

    .search-input {
        padding-left: 0;
        border: 0;
    }
}
