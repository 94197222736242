//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: 60px;
  overflow-x: hidden;
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #F1F1F1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #C1C1C1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #A8A8A8;
  }
