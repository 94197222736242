//
// modal.scss
//

.bottom-content {
    position: absolute;
    bottom: 0;
}

.modal-content {
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
}

.modal-header {
    border-bottom: 0 none;
}

.modal-footer {
    padding-top: 0;
    border-top: 0 none;
}

.modal-title {
    font-family: 'Open Sans', sans-serif;
    margin-top: 0;
}

.modal-body {
    padding-top: 0;

    .icon {
        font-size: 90px;
    }

    .description {
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        color: #2E7FEB;
    }

    .message {
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        line-height: 120%;
        color: #66686D;
    }
}

// Full modal
.modal-full {
    width: 92%;
    max-width: none;
}

// Custom animation modal
.modal-demo {
    position: relative;
    display: none;
    width: 600px !important;
    background-color: $white;
    border-radius: 4px;
    .close {
        position: absolute;
        top: 15px;
        right: 25px;
        color: $light;
    }
}

@media (max-width: 768px) {
    .modal-demo {
        width: 96% !important;
    }
}

.custom-modal-title {
    padding: 15px 25px 15px 25px;
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    color: $white;
    text-align: left;
    background-color: $dark;
}

.custom-modal-text {
    padding: 20px;
}

.custombox-modal-wrapper {
    text-align: left;
}

.custombox-modal-flash,
.custombox-modal-rotatedown {
    .close {
        top: 20px;
        z-index: 9999;
    }
}
