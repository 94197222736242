//
// authentication.scss
//

.authentication-bg.enlarged {
    min-height: 100px;
}

.bg-pattern {
    background-image: url('~~~@assets/images/bg-pattern-2.png');
    background-size: cover;
}

// authentication pages background
body.authentication-bg {
    background-color: $gray-200;
    background-size: cover;
    background-position: center;
}

body.authentication-bg-pattern {
    background-image: url('~~~@assets/images/bg-login.png');
    background-color: $primary;
}

// Logout page
.logout-icon {
    width: 140px;
}

.langs {
    display: flex;
    justify-content: center;
    img {
        margin: 12px;
        opacity: 0.5;
        cursor: pointer;
    }

    img:hover,
    img.active {
        opacity: 1;
    }
}

.form-access {
    margin-top: 32px;

    label {
        font-size: 12px;
        font-family: 'Exo2-Regular', sans-serif;
        color: #fff;
    }

    input {
        padding: 8px 16px;
        border: none;
        border-radius: 12px;
    }

    button {
        border-radius: 12px;
        border: none;
        font-size: 14px;
        font-family: 'Exo2-Bold', sans-serif;
        letter-spacing: 0.05em;
        position: relative;
        background-color: #ccc;
        background-image: linear-gradient(225deg, #808895 0%, #404249 100%);
        -webkit-backface-visibility: hidden;
        z-index: 1;
    }

    button:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background-color: #404249;
        transition: opacity 0.3s ease-out;
        z-index: 2;
        opacity: 0;
    }

    button:hover:after {
        opacity: 1;
    }

    button span {
        position: relative;
        z-index: 3;
    }
}
