/*
Template Name: Ubold - Responsive Bootstrap 4 Admin Dashboard
Version: 1.0.0
Author: CoderThemes
Email: support@coderthemes.com
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";

.icon-green {
    color: #64BB77 !important;
  }

.icon-blue {
  color: #82B2F3 !important;
}

.icon-red {
    color: #FD4084 !important;
}

.icon-orange {
    color: #F4AC57 !important;
}

.icon-gray {
    color: #66686D !important;
}

.icon-black {
    color: #000000 !important;
}

.icon-ligth {
    color: #f2f2f2 !important;
}

.icon-size-16 {
    font-size: 16px !important;
}
