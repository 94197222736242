//
// card.scss
//

@use "sass:math";

.carousel-indicators {
  li {
    &:focus {
      outline: none !important;
    }
  }
}

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $shadow-sm;

  .card-drop {
    font-size: 20px;
    line-height: 0;
    color: inherit;
  }
}

.card-pdv {
  background-color: #fff;
  box-shadow: 0 3px 3px 0 rgb(0,0,0, 0.15);
  border-top-width: 2px;
  border-radius: 12px;
  padding: 4px 16px 16px;

  .card-body {
      padding: 0;
  }
}

.card-collapse-pdv {
    background-color: #FEFEFE;
    padding: 0 20px 0 20px;

    .title {
      letter-spacing: 2%;
      font-family: 'Exo2-Bold', sans-serif;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 0.05em;
      color: #808895;
      margin-top: 5px;
      padding-left: 16px;
    }

    .fe-chevron-right, .fe-chevron-down {
        color: #808895;
    }

    &.collapsed {
        i.fe-chevron-down {
            display: none;
        }
        i.fe-chevron-right {
            display: inline;
        }
    }

    &:not(.collapsed) {
        i.fe-chevron-down {
            display: inline;
        }
        i.fe-chevron-right {
            display: none;
        }
    }
}

.card-widgets {
  float: right;
  height: 16px;
  > a {
    color: inherit;
    font-size: 18px;
    display: inline-block;
    line-height: 1;

    &.collapsed {
      i {
        &:before {
          content: "\F415";
        }
      }
    }
  }
}

// Card title / Card Header
.card-title,
.card-header {
  margin-top: 0;
}

@keyframes rotatebox {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

// Custom card box
.card-box {
  background-color: $white;
  padding: $card-spacer-x;
  box-shadow: $shadow-sm;
  margin-bottom: $grid-gutter-width;
  @include border-radius($card-border-radius);
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.sub-header {
  font-size: 0.875rem;
  margin-bottom: $grid-gutter-width;
  color: $gray-600;
}

.card-ws {
    margin-left: 4px;
    margin-right: 4px;
    transition: all 0.7s ease-in-out;
    padding-bottom: 8px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}
