@import '~vue-multiselect/dist/vue-multiselect.min.css';

.resultLimit {
    background: #f1556c;
    color: #fff;
    display: block;
    padding: 12px;
    min-height: 40px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
}

.multiselect .multiselect__tags {
    border: 1px solid #5899EF !important;
    border-radius: 12px;
    min-height: calc(1.5em + 1.1rem + 2px);

    .multiselect__spinner {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

.multiselect__single {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px;
    color: #404249 !important;
}

.multiselect--active .multiselect__tags {
    border: 1px solid #2E7FEB !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
}

.multiselect--disabled {
    background: transparent !important;
    opacity: 1 !important;

    .multiselect__select {
        z-index: 0;
        background-color: transparent !important;
        border-color: transparent !important;
    }

    .multiselect__tags {
        background-color: #eae5e5 !important;

        .multiselect__single {
            background-color: #eae5e5;
        }

        .multiselect__placeholder {
            color: transparent;
        }

        .multiselect__spinner {
            background-color: #eae5e5;
        }
    }
}

.multiselect__spinner,
.multiselect__select {
    z-index: 30;
}
.is-invalid .multiselect__spinner,
.is-invalid .multiselect__select {
    color: #f1556c !important;
}
.is-valid .multiselect__spinner,
.is-valid .multiselect__select {
    color: #1abc9c !important;
}

.is-invalid .multiselect__tags {
    border-color: #f1556c !important;
}

.is-valid .multiselect__tags {
    border-color: #1abc9c !important;
}
