//
// buttons.scss
//

.btn-label {
    padding: 0.6rem 0.9rem;
    margin: -0.55rem 0.9rem -0.55rem -0.9rem;
    background-color: rgba($gray-900, 0.1);
}

.btn-label-right {
    display: inline-block;
    padding: ($btn-padding-y) ($btn-padding-x);
    margin: (-$btn-padding-y) (-$btn-padding-x) (-$btn-padding-y)
        ($btn-padding-x);
    background-color: rgba($gray-900, 0.1);
}

.btn-warning {
    color: $gray-800;
}

.btn-rounded {
    border-radius: 2em;

    .btn-label {
        border-radius: 2em 0 0 2em;
    }

    .btn-label-right {
        border-radius: 0 2em 2em 0;
    }
}

//
// light button
//
.btn-light,
.btn-white {
    color: $gray-900;
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;

    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
        border-color: transparent;
    }
    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        border-color: transparent;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    } // No need for an active state here
}
//
// Alternate buttons
//
@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}

//
// Button Extra Small Size
//

.btn-xs {
    padding: 0.2rem 0.6rem;
    font-size: 0.75rem;
    border-radius: 0.15rem;
}

.btn-pdv {
    font-family: 'Exo2-Bold', sans-serif;
    font-size: 1em;
    font-weight: bold;
    color: #808895;
    letter-spacing: 0.05em;
    background-color: transparent !important;
    border: 0 !important;
    border-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    transition: all 0.3s;
}

.btn-pdv:hover {
    font-weight: bold;
    background-color: transparent;
    border: 0;
    border-color: transparent;
}

.btn-pdv:focus {
    font-weight: bold;
    color: #808895;
    background-color: transparent;
    border: 0;
    border-color: transparent;
}

.btn-pdv-append-blue {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.btn-pdv-blue {
    &:hover:enabled {
        color: #82b2f3;
    }

    &:focus:enabled {
        color: #32bdf9;
    }

    &:active:enabled {
        color: #2e7feb;
    }
}

.btn-pdv-green {
    &:hover:enabled {
        color: #9cd4a8;
    }

    &:focus:enabled {
        color: #89E09C;
    }

    &:active:enabled {
        color: #64bb77;
    }
}

.btn-pdv-red {
    &:hover:enabled {
        color: #fb3737;
    }

    &:focus:enabled {
        color: #FD4084;
    }

    &:active:enabled {
        color: #af0404;
    }
}

.btn-pdv-orange {
    &:hover:enabled {
        color: #f5a070;
    }

    &:focus:enabled {
        color: #F4AC57;
    }

    &:active:enabled {
        color: #ed6112;
    }
}

.btn-pdv-gradient-blue {
    height: 40px;
    font-family: 'Exo2-Bold', sans-serif;
    font-size: 1em;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.05em;
    background: linear-gradient(225deg, #32bdf9 0%, #2e7feb 100%);
    border: 0 !important;
    border-color: transparent !important;
    border-radius: 12px;
    outline: none !important;
    box-shadow: none !important;
    transition: opacity 0.5s;
    animation: 0.8s linear infinite;
    margin-top: 0px !important;

    &:hover:enabled {
        background: #32bdf9;
    }

    &:focus {
        background: linear-gradient(225deg, #32bdf9 0%, #2e7feb 100%);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
    }

    &:active:enabled {
        background: #2e7feb !important;
    }
}

.btn-pdv-gradient-blue-transparent {
    height: 40px;
    font-family: 'Exo2-Bold', sans-serif;
    font-size: 1em;
    font-weight: bold;
    color: #82B2F3 !important;
    letter-spacing: 0.05em;
    background: transparent;
    border: 1px solid !important;
    border-color: #82B2F3 !important;
    border-radius: 12px;
    outline: none !important;
    box-shadow: none !important;
    transition: opacity 0.5s;
    animation: 0.8s linear infinite;
    margin-top: 0px !important;

    &:hover:enabled {
        background: #D6F2FE;
    }

    &:focus {
        background: transparent;
        border-color: #82B2F3 !important;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
    }

    &:active:enabled {
        color: #2E7FEB !important;
        background: #D5E5FB !important;
        border-color: #2E7FEB !important;
    }

    &:disabled {
        color: #D9D9DB !important;
        background: #FFF !important;
        border-color: #D9D9DB !important;
    }
}

.btn-pdv-gradient-red {
    height: 40px;
    font-family: 'Exo2-Bold', sans-serif;
    font-size: 1em;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.05em;
    background: linear-gradient(225deg, #FD4084 0%, #AF0404 99.99%);
    border: 0 !important;
    border-color: transparent !important;
    border-radius: 12px;
    outline: none !important;
    box-shadow: none !important;
    transition: opacity 0.5s;
    animation: 0.8s linear infinite;
    margin-top: 0px !important;

    &:hover:enabled {
        background: #FD4084;
    }

    &:focus {
        background: linear-gradient(225deg, #FD4084 0%, #AF0404 99.99%);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
    }

    &:active:enabled {
        background: #AF0404 !important;
    }
}

.btn-pdv-gradient-red-transparent {
    height: 40px;
    font-family: 'Exo2-Bold', sans-serif;
    font-size: 1em;
    font-weight: bold;
    color: #FB3737 !important;
    letter-spacing: 0.05em;
    background: transparent;
    border: 1px solid !important;
    border-color: #FB3737 !important;
    border-radius: 12px;
    outline: none !important;
    box-shadow: none !important;
    transition: opacity 0.5s;
    animation: 0.8s linear infinite;
    margin-top: 0px !important;

    &:hover:enabled {
        background: #FFE6EF;
    }

    &:focus {
        background: transparent;
        border-color: #AF0404 !important;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
    }

    &:active:enabled {
        color: #AF0404 !important;
        background: #FFE6EF !important;
        border-color: #AF0404 !important;
    }

    &:disabled {
        color: #D9D9DB !important;
        background: #FFF !important;
        border-color: #D9D9DB !important;
    }
}

.btn-pdv-gradient-yellow {
    height: 40px;
    font-family: 'Exo2-Bold', sans-serif;
    font-size: 1em;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.05em;
    background: linear-gradient(225deg, #F3D63F 0%, #EDB012 100%);
    border: 0 !important;
    border-color: transparent !important;
    border-radius: 12px;
    outline: none !important;
    box-shadow: none !important;
    transition: opacity 0.5s;
    animation: 0.8s linear infinite;
    margin-top: 0px !important;

    &:hover:enabled {
        background: #F7E585;
    }

    &:focus {
        background: linear-gradient(225deg, #F3D63F 0%, #EDB012 100%);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
    }

    &:active:enabled {
        background: #EDB012 !important;
    }
}

.btn-pdv-gradient-yellow-transparent {
    height: 40px;
    font-family: 'Exo2-Bold', sans-serif;
    font-size: 1em;
    font-weight: bold;
    color: #F5CF70 !important;
    letter-spacing: 0.05em;
    background: transparent;
    border: 1px solid !important;
    border-color: #F5CF70 !important;
    border-radius: 12px;
    outline: none !important;
    box-shadow: none !important;
    transition: opacity 0.5s;
    animation: 0.8s linear infinite;
    margin-top: 0px !important;

    &:hover:enabled {
        background: #FDFAE7;
    }

    &:focus {
        background: transparent;
        border-color: #F5CF70 !important;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
    }

    &:active:enabled {
        color: #EDB012 !important;
        background: #FDFAE7 !important;
        border-color: #EDB012 !important;
    }
}

.btn-pdv-gradient-gray {
    height: 40px;
    font-family: 'Exo2-Bold', sans-serif;
    font-size: 1em;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.05em;
    background: linear-gradient(225deg, #808895 0%, #404249 100%);
    border: 0 !important;
    border-color: transparent !important;
    border-radius: 12px;
    outline: none !important;
    box-shadow: none !important;
    transition: opacity 0.5s;
    animation: 0.8s linear infinite;
    margin-top: 0px !important;

    &:hover:enabled {
        background: #66686D;
    }

    &:focus {
        background: linear-gradient(225deg, #808895 0%, #404249 100%);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
    }

    &:active:enabled {
        background: #404249 !important;
    }
}

.btn-pdv-gradient-green {
    height: 40px;
    font-family: 'Exo2-Bold', sans-serif;
    font-size: 1em;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.05em;
    background: linear-gradient(225deg, #89E09C 0%, #64BB77 100%);
    border: 0 !important;
    border-color: transparent !important;
    border-radius: 12px;
    outline: none !important;
    box-shadow: none !important;
    transition: opacity 0.5s;
    animation: 0.8s linear infinite;
    margin-top: 0px !important;

    &:hover:enabled {
        background: #89E09C;
    }

    &:focus {
        background: linear-gradient(225deg, #89E09C 0%, #64BB77 100%);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
    }

    &:active:enabled {
        background: #64BB77 !important;
    }
}
