@import '~@riophae/vue-treeselect/dist/vue-treeselect.css';

.vue-treeselect__control {
    border: 1px solid #5899EF !important;
    border-radius: 12px !important;
    min-height: calc(1.5em + 1.1rem + 1px);
    font-family: 'Exo2-Regular', sans-serif !important;
    font-size: 14px;
    font-weight: 400 !important;
    color: #404249 !important;
}

.vue-treeselect--focused .vue-treeselect__control {
    border: 1px solid #2E7FEB !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
}

.vue-treeselect--disabled .vue-treeselect__control {
    background-color: #eae5e5 !important;
}

.is-invalid {
    & .vue-treeselect__control,
    & input.mx-input {
        border-color: #f1556c !important;
    }
}

.is-valid {
    & .vue-treeselect__control,
    & input.mx-input {
        border-color: #1abc9c !important;
    }
}
