//
// date-picker.scss
//

.input-date > div > input {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #404249;
    border: 1px solid #5899EF;
    box-sizing: border-box;
    border-radius: 12px 0px 0px 12px !important;
    border-right: 0;
    min-height: calc(1.5em + 1.0rem);

    &:focus {
        border: 1px solid #2E7FEB !important;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
    }
}

.input-date-disabled > div > input {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #404249;
    border: 1px solid #5899EF;
    box-sizing: border-box;
    border-radius: 12px 0px 0px 12px !important;
    border-right: 0;
    background-color: #eae5e5;
    pointer-events:none;
    min-height: calc(1.5em + 1.0rem);
}

.clear-date-disabled > div > input {
    border-radius: 12px !important;
    border: 1px solid #5899EF !important;
}

.btn-clean {
    border-left: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 12px;
    background-color: #5899EF;
    color: #FFF !important;

    &:hover:enabled {
        background-color: #82b2f3;
    }

    &:focus {
        background: #82b2f3;
    }

    &:active:enabled {
        background-color: #2e7feb;
    }
}

.mx-datepicker {

    .mx-input {
        font-family: 'Open Sans', sans-serif;
        color: #404249;
        border: 1px solid #5899EF !important;
        box-sizing: border-box;
        border-radius: 12px !important;
    }
}

.is-invalid > div {
    input {
        border-color: #f1556c !important;
    }

    .btn-clean {
        border-color: #f1556c !important;
    }
}

.is-valid > div {
    input {
        border-color: #1abc9c !important;
    }

    .btn-clean {
        border-color: #1abc9c !important;
    }
}
