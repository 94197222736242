//
// Google font - Nunito
//

@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700,900');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
//
// Premium font - Cerebri Sans
//

@font-face {
    font-family: 'Cerebri Sans,sans-serif';
    src: url('~~~@assets/fonts/cerebrisans-light.eot');
    src: local('Cerebri-sans Light'),
        url('~~~@assets/fonts/cerebrisans-light.woff') format('woff');
    font-weight: 300;
}

@font-face {
    font-family: 'Exo2-Regular';
    src: url('~~~@assets/fonts/Exo2-Regular.eot');
    src: local('Exo2-Regular'),
        url('~~~@assets/fonts/Exo2-Regular.woff') format('woff');
    font-weight: 300;
}

@font-face {
    font-family: 'Exo2-Bold';
    src: url('~~~@assets/fonts/Exo2-Bold.eot');
    src: local('Exo2-Bold'),
        url('~~~@assets/fonts/Exo2-Bold.woff') format('woff');
    font-weight: 700;
}

@font-face {
    font-family: 'Cerebri Sans,sans-serif';
    src: url('~~~@assets/fonts/cerebrisans-regular.eot');
    src: local('Cerebri-sans Regular'),
        url('~~~@assets/fonts/cerebrisans-regular.woff') format('woff');
    font-weight: 400;
}

@font-face {
    font-family: 'Cerebri Sans,sans-serif';
    src: url('~~~@assets/fonts/cerebrisans-medium.eot');
    src: local('Cerebri-sans Medium'),
        url('~~~@assets/fonts/cerebrisans-medium.woff') format('woff');
    font-weight: 500;
}

@font-face {
    font-family: 'Cerebri Sans,sans-serif';
    src: url('~~~@assets/fonts/cerebrisans-semibold.eot');
    src: local('Cerebri-sans Semibold'),
        url('~~~@assets/fonts/cerebrisans-semibold.woff') format('woff');
    font-weight: 600;
}

@font-face {
    font-family: 'Cerebri Sans,sans-serif';
    src: url('~~~@assets/fonts/cerebrisans-bold.eot');
    src: local('Cerebri-sans Bold'),
        url('~~~@assets/fonts/cerebrisans-bold.woff') format('woff');
    font-weight: 700;
}
