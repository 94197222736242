//
// forms.scss
//

// Form-control light
.form-control-light {
    background-color: $gray-100;
    border-color: $gray-100;
}

.comment-area-box {
    .form-control {
        border-color: $gray-300;
        border-radius: $input-border-radius $input-border-radius 0 0;
    }

    .comment-area-btn {
        padding: 10px;
        background-color: $gray-100;
        border: 1px solid $gray-300;
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
    }
}

// Form elements (Color and Range)
input.form-control[type='color'],
input.form-control[type='range'] {
    min-height: 39px;
}

.custom-file-label {
    border-radius: 12px;
    border: 1px solid #5899EF;
    box-sizing: border-box;
    border-radius: 12px !important;
}

.custom-file-label::after {
    background-color: #2e7feb;
    color: #FFF;
}

.required > label::after {
    color: red;
    content: ' *';
}

.label-pdv {
    color: #808895;
    font-family: 'Exo2-Bold', sans-serif;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.05em;
}

.label-pdv-invalid {
    color: #f1556c;
    font-family: 'Exo2-Bold', sans-serif;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.05em;
}

.text-pdv {
    color: #99a0aa;
    font-family: 'Open Sans', sans-serif;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px;
    line-height: 120%;
}

.input-pdv-blue {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #404249;
    border: 1px solid #5899ef;
    box-sizing: border-box;
    border-radius: 12px;

    &:focus {
        border: 1px solid #2E7FEB !important;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
    }

    &:disabled {
        background-color: #eae5e5;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.input-group-pdv-blue {
    > .input-group-prepend > .input-group-text {
        border-right: 0;
        border-top-left-radius: 12px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 0;
        background-color: #5899ef;
        color: #fff !important;
    }

    > .input-group-append > .input-group-text {
        border-left: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 12px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 12px;
        background-color: #5899ef;
        color: #fff !important;
    }
}

.select-pdv-blue {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #404249;
    border: 1px solid #5899ef;
    border-radius: 12px;

    &:focus {
        border: 1px solid #5899ef;
    }
}

.editor-pdv-blue {
    .ql-disabled {
        background-color: #eae5e5;
    }

    .ql-toolbar {
        border: 1px solid #5899ef !important;
        box-sizing: border-box;
        border-radius: 12px 12px 0 0;
    }

    .ql-container {
        border: 1px solid #5899ef !important;
        border-top: 0 !important;
        box-sizing: border-box;
        border-radius: 0 0 12px 12px;
    }
}


.is-invalid {
    .ql-toolbar {
        border-color: #f1556c !important;
    }

    .ql-container {
        border-color: #f1556c !important;
    }
}

.is-valid {
    .ql-toolbar {
        border-color: #1abc9c !important;
    }

    .ql-container {
        border-color: #1abc9c !important;
    }
}
