//
// tables.scss
//

// Table centered (Custom)
.table-centered {
    td,
    th {
        vertical-align: middle !important;
    }
}

.table thead th {
    font-family: 'Exo2', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    border-top: 0;
    border-color: #F6F6F6;
    outline: none !important;
    color: #66686D;

    &:hover {
        color: #2E7FEB !important;
    }
}

.table td {
  border-color: #F3F3F3;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #66686D;
}

// Custom table components (Custom)
.table {
    .table-user {
        img {
            width: 30px;
            height: 30px;
        }
    }
}

.action-icon {
    display: inline-block;
    padding: 0 3px;
    font-size: 1.2rem;
    color: $gray-600;

    &:hover {
        color: $gray-700;
    }
}


.table-hover tbody tr:hover td {
  background: #F7FDF8;
}

.table-pdv {
    border: 1px solid #0099d9;
    box-sizing: border-box;
    border-radius: 20px;
    margin-bottom: 2px !important;
    min-height: 300px;
}

.table-pdv-sm {
    min-height: 150px;
}

.table-handle {
    cursor: grab;
}

.table-cursor-grabbing * {
    cursor: grabbing !important;
  }
