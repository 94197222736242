//
// card.scss
//

.tabs-pdv {
    .nav-tabs {
        border-bottom: 0;
    }

    .nav-item {
        display: flex;
        margin-right: 12px;
    }

    .nav-link {
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.03em;
        color: #6c757d;
        font-family: 'Exo2-Bold', sans-serif;
        font-size: 13px;
        font-weight: 700;
        text-decoration: none;
        background-color: #fff;
        box-shadow: 0 2px 5px 0 rgb(0, 0, 0, 0.15) !important;
        border-radius: 10px !important;
        border-color: transparent !important;
        min-width: 172px;
        border: none;
        padding: 10px 0;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: #568fe7;
            color: #fff;
        }

        &:active {
            background: #2e7feb !important;
            color: #fff;
        }

        &.active {
            background: #2e7feb;
            color: #fff;
        }

        &.disabled {
            background: $gray-300;
        }
    }
}
